define("discourse/plugins/chs-custom-endpoints/discourse/controllers/notebook", ["exports", "ember", "@ember/service"], function (_exports, _ember, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Controller.extend({
    init() {
      console.log("Here we are in init");
      console.log(this.currentUser);
      this._super();
      this.set('notes', []);
      this.fetchNotes();
    },
    fetchNotes() {
      this.store.findAll('note').then(result => {
        for (const note of result.content) {
          this.notes.pushObject(note);
        }
      }).catch(console.error);
    },
    actions: {
      createNote(content) {
        if (!content) {
          return;
        }
        const noteRecord = this.store.createRecord('note', {
          id: Date.now(),
          content: content
        });
        noteRecord.save().then(result => {
          this.notes.pushObject(result.target);
        }).catch(console.error);
      },
      deleteNote(note) {
        this.store.destroyRecord('note', note).then(() => {
          this.notes.removeObject(note);
        }).catch(console.error);
      }
    }
  });
});