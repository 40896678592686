define("discourse/plugins/chs-custom-endpoints/discourse/routes/magic-coming-up", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    renderTemplate() {
      // Renders the template `../templates/magic.hbs`
      this.render('magic');
    }
  });
});