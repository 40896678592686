define("discourse/plugins/chs-custom-endpoints/discourse/templates/magic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>Notebook</h1>
  <javascript>
  console.log("Username:" + username);
  </javascript>
  
  */
  {
    "id": "t63cHAqb",
    "block": "[[[10,\"h1\"],[12],[1,\"Notebook\"],[13],[1,\"\\n\"],[10,\"javascript\"],[12],[1,\"\\nconsole.log(\\\"Username:\\\" + username);\\n\"],[13],[1,\"\\n\"]],[],false,[\"h1\",\"javascript\"]]",
    "moduleName": "discourse/plugins/chs-custom-endpoints/discourse/templates/magic.hbs",
    "isStrictMode": false
  });
});